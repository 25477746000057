import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../interceptors/axios";
import { truncateToTwoDecimals } from "../utils";

export const createCheckoutSession = async (priceId) => {
  try {
    const response = await api.post(
      "api/v3/stripePayments/create-checkout-session",
      {
        priceId, // Replace with your actual priceId
      }
    );

    // Redirect to the returned URL
    window.location.href = response.data.data;
  } catch (error) {
    console.error("Error creating checkout session:", error);
  }
};

export const checkSession = async (sessionId) => {
  try {
    const response = await api.get(
      `api/v3/stripePayments/check-session?sessionId=${sessionId}`
    );

    return response.data.data;
  } catch (error) {
    console.error("Error when check session:", error);
  }
};

export const checkSubscription = createAsyncThunk(
  "checkSubscription",
  async () => {
    try {
      const response = await api.get(
        "api/v3/stripePayments/subscription-status"
      );

      return response.data.data;
    } catch (error) {
      console.error("Error when check subscription:", error);
    }
  }
);

export const manageBilling = async () => {
  try {
    const response = await api.get("api/v3/stripePayments/manage-billing");

    // Redirect to the returned URL
    window.location.href = response.data.data;
  } catch (error) {
    console.error("Error manage billing:", error);
  }
};

export const cancelSubscription = async (subscriptionId) => {
  try {
    const response = await api.post(
      "api/v3/stripePayments/cancel-subscription",
      {
        subscriptionId,
      }
    );

    return response.data.data;
  } catch (error) {
    console.error("Error cancel subscription:", error);
  }
};

export const updateSubscription = async (subscriptionId, newPriceId) => {
  try {
    const response = await api.post(
      "api/v3/stripePayments/update-subscription",
      {
        subscriptionId,
        newPriceId,
      }
    );

    return response.data.data;
  } catch (error) {
    console.error("Error cancel subscription:", error);
  }
};

export const getStripeConfigs = createAsyncThunk(
  "getStripeConfigs",
  async () => {
    try {
      const response = await api.get("/api/v3/stripePayments/config");

      return response.data?.data?.prices
        .sort((price) => (price.recurring.interval === "year" ? -1 : 1))
        .map((price) => {
          const unitAmount = price.unit_amount / 100; // Convert from cents to dollars

          // Calculate weekly price based on the billing interval
          const weeklyPrice =
            price.recurring.interval === "month"
              ? truncateToTwoDecimals(unitAmount / 4) // Approximate weeks in a month
              : truncateToTwoDecimals(unitAmount / 52); // Weeks in a year

          // Determine how to display the billing cycle in the description
          const isMonthly = price.recurring.interval === "month";
          const billingDescription = `$${weeklyPrice}`;

          return {
            id: price.id,
            name: price.product.name,
            price: weeklyPrice,
            priceFormatted: `Billed every ${price.recurring.interval}`,
            billingCycle: price.recurring.interval,
            description: billingDescription,
            isPopular: isMonthly ? false : true,
          };
        });
    } catch (error) {
      console.error("Error getting Stripe configs:", error);
    }
  }
);
